import { Link } from "react-router-dom";

export default function ContactSentError() {
  return (
    <>
      <div className="mail-sent error">
      送信に失敗しました。ご迷惑をおかけして申し訳ございません。<Link to={`/access`} className="btn">アクセス</Link> ページにあるメールアドレスあるいは電話番号で連絡してください。
      </div>

      <div className="actions">
        <Link to={`/`} className="btn">トップページに戻る</Link>
      </div>
    </>
  );
}