export default function Access() {
  return (
    <>
      <h2 className="page-title">
        <i className="fa fa-fw fa-leaf"></i>
        アクセス
      </h2>
      <p>
        あっぷるみんとハーブ農園までの地図です。お気をつけておこしください。<a href="https://goo.gl/maps/8YgiY" className="wide-only inline" target="_blank" rel="noreferrer">グーグルマップで見る <i className="fa fa-fw fa-external-link"></i></a>
      </p>
      <div className="full-img map">
        <div className="wide-only">
          <img src={"/images/map.jpg"} alt="あっぷるみんとハーブ農園までの地図" />
        </div>
        <div className="mobi-only">
          <iframe title="Google map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3342.523899338755!2d131.084161!3d33.095307999999996!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35413a4c2e2ff603%3A0x38c93536d7f9c989!2z44GC44Gj44G344KL44G_44KT44Go44OP44O844OW6L6y5ZyS44O76Ieq54S26aOf44Os44K544OI44Op44OzIOmiqOOBruOCguOCig!5e0!3m2!1szh-TW!2stw!4v1425023475425" width="600" height="450" frameBorder="0" style={{border:0}}></iframe>
        </div>
      </div>
      <h3 className="page-subtitle">
        あっぷるみんとハーブ農園 ＆ 自然食レストラン　風のもり
      </h3>
      <ul className="info">
        <li>
          <i className="fa fa-fw fa-map-marker"></i>
          <div>
            住所：〒869-2401 熊本県阿蘇郡南小国町満願寺312<div className="wide-only"><a href="https://goo.gl/maps/8YgiY" target="_blank" rel="noreferrer">グーグルマップで見る <i className="fa fa-fw fa-external-link"></i></a></div>
          </div>
        </li>
        <li><i className="fa fa-fw fa-phone"></i>TEL：0967-42-1553</li>
        <li><i className="fa fa-fw fa-fax"></i>FAX：0967-42-1249</li>
        <li><i className="fa fa-fw fa-envelope"></i>EMAIL：<a href="mailto:applemint.herb@gmail.com">applemint.herb@gmail.com</a></li>
        <li><i className="fa fa-fw fa-clock-o"></i>営業時間：11:00 ~ 17:00</li>
        <li><i className="fa fa-fw fa-calendar"></i><span>定休日：月曜日・火曜日・金曜日・不定休</span></li>
      </ul>
    </>
  );
}