import { Link } from "react-router-dom";

export default function Efforts() {
  return (
    <>
      <h2 className="page-title">
        <i className="fa fa-fw fa-leaf"></i>
        取り組み
      </h2>
      <div className="content-block-wrapper content-block-wrapper--iswitch">
        <div className="content-block">
          <h3 className="page-subtitle">あっぷるみんとハーブ農園の取り組み</h3>
          <div className="content-block__content">
            <div className="content-block__text">
              <h4 className="has-icon"><i className="fa fa-fw fa-tint"></i>自然栽培によるきよら米の生産、販売</h4>
              <h4 className="has-icon"><i className="fa fa-fw fa-tint"></i>自然栽培による野菜、ハーブ、ブルーベリー作り</h4>
              <h4 className="has-icon"><i className="fa fa-fw fa-tint"></i>農園産の作物を直接提供するレストラン「自然食レストラン　風のもり」</h4>
              <h4 className="has-icon"><i className="fa fa-fw fa-tint"></i>農園産作物による加工品作り</h4>
              <p>
                生産から調理、加工、販売まで阿蘇南小国で生まれる製品作りを楽しみながら暮らしています。
              </p>
            </div>
            <div className="content-block__img">
              <img src={"/images/efforts-1.jpg"} alt="きよら米のマーク" />
            </div>
          </div>
        </div>
        <div className="content-block">
          <h3 className="page-subtitle">サポーター制度</h3>
          <div className="content-block__content">
            <div className="content-block__text">
              <p>
                あっぷるみんとハーブ農園では、サポーター（会員）制度を取り入れています。
              </p>
              <p>
                これは単にお店とお客様という関係だけではなく、ご来店やイベントへの参加などを通し 『持続可能な循環型社会』 『環境保全型農業』 などを主体とした新しいコミュニティー作りにも参加していただきたく立ち上げたものです。
              </p>
              <p>
                入会時に1000円お支払いいただくことによって、どなたでも入会いただけます。以後年会費などは不要です。
              </p>
              <p>
                ご入会いただきますと、あっぷるみんと商品の会員割引や農園の様子・イベントへのお誘いなどを 載せた季節のお便りの発送などがあります。
              </p>
              <p>
                多くの皆さんの参加をお待ちしています。詳しいお問い合わせは、<Link to={`contact`}>こちら</Link>まで。
              </p>
            </div>
            <div className="content-block__img">
              <img src={"/images/efforts-2.jpg"} alt="あっぷるみんとのファームドッグ" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}