import { Link } from "react-router-dom";

export default function Index() {
  return (
    <>
      <div className="full-img">
      <img src={"/images/index-0.jpg"} alt="あっぷるみんと" />
      </div>
      <div className="content-block-wrapper content-block-wrapper--iswitch">
        <div className="content-block">
          <h2 className="page-title">
            <i className="fa fa-fw fa-leaf"></i>
            風のもり
          </h2>
          <div className="content-block__content">
            <div className="content-block__text">
              <p>
                無農薬・有機栽培のお米や野菜を使用し、素材本来の味を堪能出来る料理とくつろぎの空間でゆったりとした時の流れをお楽しみください。<br />
                ゆっくり流れる四季の移ろいの中で、太陽の香りがするお米や野菜の美味しさを実感する料理をご用意し、皆様のお越しをお待ちしております。
              </p>
              <Link to={`/restaurant`} className="more"><i className="fa fa-fw fa-pagelines"></i>もっと見る</Link>
            </div>
            <div className="content-block__img">
              <img src={"/images/index-1.jpg"} alt="風のもり" />
            </div>
          </div>
        </div>
        <div className="content-block">
          <h2 className="page-title">
            <i className="fa fa-fw fa-leaf"></i>
            農園
          </h2>
          <div className="content-block__content">
            <div className="content-block__text">
              <p>
                昭和61年より“自然栽培”（無農薬・無科学肥料・無除草剤栽培）を続けている“きよら米づくり”(1.4ha）を農園のベースと考え毎年大切に育てています。また農業を通じて環境を良くしていく“環境保全型農業”を目指し、源流に位置する筑後川を少しでもキレイにしていきたいという思いも込め自然栽培にこだわっています。
              </p>
              <Link to={`/farm`} className="more"><i className="fa fa-fw fa-pagelines"></i>もっと見る</Link>
            </div>
            <div className="content-block__img">
              <img src={"/images/index-2.jpg"} alt="農園" />
            </div>
          </div>
        </div>
        <div className="content-block">
          <h2 className="page-title">
            <i className="fa fa-fw fa-leaf"></i>
            商品
          </h2>
          <div className="content-block__content">
            <div className="content-block__text">
              <p>
                農園で自然栽培された作物を中心に、地元産などのこだわり食材を使っています。安心して召し上がっていただけるよう、添加物を一切使わずに作りました。<br />
                ほかにもいろいろな商品がございます。ぜひ一度足をお運びください。
              </p>
              <Link to={`/goods`} className="more"><i className="fa fa-fw fa-pagelines"></i>もっと見る</Link>
            </div>
            <div className="content-block__img">
              <img src={"/images/index-3.jpg"} alt="商品" />
            </div>
          </div>
        </div>
        <div className="content-block">
          <h2 className="page-title">
            <i className="fa fa-fw fa-leaf"></i>
            取り組み
          </h2>
          <div className="content-block__content">
            <div className="content-block__text">
              <p>
                あっぷるみんとハーブ農園では、サポーター（会員）制度を取り入れています。<br />
                ご入会いただきますと、あっぷるみんと商品の会員割引や農園の様子・イベントへのお誘いなどを載せた季節のお便りの発送などがあります。
              </p>
              <Link to={`/efforts`} className="more"><i className="fa fa-fw fa-pagelines"></i>もっと見る</Link>
            </div>
            <div className="content-block__img">
              <img src={"/images/index-4.jpg"} alt="あっぷるみんとのファームドッグ" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}