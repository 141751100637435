export default function Restaurant() {
  return (
    <>
      <h2 className="page-title">
        <i className="fa fa-fw fa-leaf"></i>
        風のもり
      </h2>
      <div className="content-block-wrapper">
        <div className="content-block">
          <h3 className="page-subtitle">お店紹介</h3>
          <div className="content-block__content">
            <div className="content-block__text">
            <p>
              阿蘇・南小国の風土の中で、大切に育てられた素材たち...。 <br />
              一番美味しい時期に食べていただきたく、たくさんの手間と愛情をこめて天然の調味料だけを使ってやさしい味付けにしました。 <br />
              のどかな風景をを楽しみながら体も心もゆっくりゆっくり元気になって下さい。
            </p>
            </div>
            <div className="content-block__img">
              <img src={"/images/restaurant-1.jpg"} alt="風のもり" />
            </div>
          </div>
        </div>
        <div className="content-block">
          <h3 className="page-subtitle">MENU</h3>
          <div className="content-block__content">
            <div className="content-block__text">
              <p>
                農園から採れる旬の野菜からメニューを創作し、厳選素材の旬の旨味、お米本来の旨さを五感で味わう料理の数々。
              </p>
              <p>
                素材の土作りから調味料まで、心や体そして環境にもやさしい料理を心がけております。
              </p>
              <p>
                マクロビオティックの考えを基に作られた料理や、アレルギーを持つお子様も安心して楽しめる料理などもご用意いたしました。
              </p>
            </div>
            <div className="content-block__img">
              <img src={"/images/restaurant-2.jpg"} alt="風のもりMENU" />
            </div>
          </div>
        </div>
        <div className="content-block">
          <h3 className="page-subtitle">こだわり</h3>
          <div className="content-block__content">
            <div className="content-block__text">
              <p>                
                環境保全型農園で有機質肥料さえも極力減らし無農薬栽培された玄米（きよら米）を始め、無農薬・有機栽培の野菜や果物、保存料、添加物を一切使わない調味料など、安心して美味しく食べられるように美味しさだけにこだわらず、素材の作り方から気を配り、環境にも体にもやさしい料理に仕上げました。
              </p>
              <p>
                太陽の下、地元で育った旬の食材をお楽しみください。
              </p>
            </div>
            <div className="content-block__img">
              <img src={"/images/restaurant-3.jpg"} alt="きよら米" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}