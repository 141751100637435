import React from 'react';
import emailjs from '@emailjs/browser';
import { Navigate } from "react-router-dom";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
      error: false,
    }
    this.sendEmail = this.sendEmail.bind(this);
  }
  sendEmail(e) {
    e.preventDefault();
    document.getElementById("submit-button").innerHTML = "<div class='loading'><span>Loading</span></div>";
    document.getElementById("submit-button").disabled = true;

    emailjs.sendForm("service_3yyihen", "template_ni2dtzo", "#contact-form", "WZCFCSBFxIGfUfSz9")
      .then((result) => {
        console.log(result.text);
        this.setState({
          sent: true
        });  
      }, (error) => {
        console.log(error.text);
        this.setState({
          error: true
        });
      });
  }
  render() {
    if (this.state.sent) {
      return <Navigate to={"/contact-sent"}/>
    }
    else if (this.state.error) {
      return <Navigate to={"/contact-sent-error"}/>
    }
    return (
      <>
        <h2 className="page-title">
          <i className="fa fa-fw fa-leaf"></i>
          お問い合わせ
        </h2>
        <p>
          あっぷるみんと・風のもりに関する疑問、ご意見ご感想などございましたら、こちらのフォームよりお気軽にお問合せください。
        </p>
        <form  id="contact-form" onSubmit={this.sendEmail} className="contact-info">
          <div className="user-info">
            <div className="control-group">
              <label className="control-label">
                <span className="required">*</span>メールアドレス
              </label>
              <div className="controls">
                <input type="email" name="reply_to" className="full" required onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
              </div>
            </div>
            <div className="control-group">
              <label className="control-label">
                <span className="required">*</span>お名前
              </label>
              <div className="controls">
                <input type="text" name="from_name" required onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
              </div>
            </div>
            <div className="control-group">
              <label className="control-label">
                <span className="required">*</span>内容
              </label>
              <div className="controls">
                <textarea name="message" className="full" required />
              </div>
            </div>
          </div>
          <div className="actions">
            <button id="submit-button" type="submit" className="btn">送信</button>
          </div>
        </form>
      </>
    );
  }
}

export default Contact;