import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './stylesheets/index.scss';
import reportWebVitals from './reportWebVitals';
import Root from "./routes/root";
import Index from "./routes/index";
import ErrorPage from "./error-page";
import Contact from "./routes/contact";
import ContactSent from "./routes/contact-sent";
import ContactSentError from "./routes/contact-sent-error";
import Restaurant from "./routes/restaurant";
import Farm from "./routes/farm";
import Purchase from "./routes/purchase";
import PurchaseSent from "./routes/purchase-sent";
import Goods from "./routes/goods";
import Access from "./routes/access";
import Efforts from "./routes/efforts";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { 
        index: true, 
        element: <Index /> 
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "contact.html",
        element: <Contact />,
      },
      {
        path: "contact-sent",
        element: <ContactSent />,
      },
      {
        path: "contact-sent-error",
        element: <ContactSentError />,
      },
      {
        path: "restaurant",
        element: <Restaurant />,
      },
      {
        path: "restaurant.html",
        element: <Restaurant />,
      },
      {
        path: "farm",
        element: <Farm />,
      },
      {
        path: "farm.html",
        element: <Farm />,
      },
      {
        path: "purchase",
        element: <Purchase />,
      },
      {
        path: "purchase.html",
        element: <Purchase />,
      },
      {
        path: "purchase-sent",
        element: <PurchaseSent />,
      },
      {
        path: "goods",
        element: <Goods />,
      },
      {
        path: "goods.html",
        element: <Goods />,
      },
      {
        path: "efforts",
        element: <Efforts />,
      },
      {
        path: "efforts.html",
        element: <Efforts />,
      },
      {
        path: "access",
        element: <Access />,
      },
      {
        path: "access.html",
        element: <Access />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
