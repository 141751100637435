import { Link } from "react-router-dom";

export default function PageFooter() {
  return (
    <>
      <footer className="page-footer">
        <div className="container">
          <div className="message">
            <div className="long">『あっぷるみんとハーブ農園』&amp;『自然食レストラン　風のもり』</div>
            <div className="short">営業時間：11:00 ~ 17:00</div>
            <div className="short">定休日：月曜日・火曜日・金曜日・不定休</div>
          </div>
          <ul className="nav">
            <li className="contact">
              <Link to={`/contact`}>お問い合わせ</Link>
            </li>
            <li className="shop">
              <a href="http://store.shopping.yahoo.co.jp/syokunosanpo/a4aacac6a1.html" target="_blank" rel="noreferrer">ネットショップ</a>
            </li>
            <li className="blog">
              <a href="http://blog.goo.ne.jp/applemint-kazenomori" target="_blank" rel="noreferrer">ブログ</a>
            </li>
            <li className="fb">
              <a href="https://www.facebook.com/pages/%E3%81%82%E3%81%A3%E3%81%B7%E3%82%8B%E3%81%BF%E3%82%93%E3%81%A8%E3%83%8F%E3%83%BC%E3%83%96%E8%BE%B2%E5%9C%92%E9%A2%A8%E3%81%AE%E3%82%82%E3%82%8A/1424061631175383" target="_blank" rel="noreferrer">Facebook</a>
            </li>
          </ul>
          <ul className="shop-contact">
            <li>
              <i className="fa fa-map-marker"></i>
              <span className="post-code">〒869-2401</span> 熊本県阿蘇郡南小国町満願寺312
            </li>
            <li>
              <i className="fa fa-phone"></i>
              0967-42-1553
            </li>
          </ul>
          <p className="copyright">
            Copyright&copy; 2014 applemint all right reserved
          </p>
        </div>
      </footer>
    </>
  );
}