import { Link } from "react-router-dom";

export default function Goods() {
  return (
    <>
      <h2 className="page-title">
        <i className="fa fa-fw fa-leaf"></i>
        商品紹介
      </h2>
      <p>
        農園で自然栽培された作物を中心に、地元産などのこだわり食材を使っています。<br />
        安心して召し上がっていただけるよう、添加物を一切使わずに作りました。<br />
        ほかにもいろいろな商品がございます。ぜひ一度足をお運びください。
      </p>
      <div className="content-block-wrapper content-block-wrapper--ileft">
        <div className="content-block">
          <h3 className="page-subtitle">無農薬栽培米　『きよら米』</h3>
          <div className="content-block__content">
            <div className="content-block__text">
              <p>
                筑後川の源流で20年以上自然栽培を続けてきた「きよら米」。<br />
                1kgずつ真空パックの個別包装で販売しています。<br />
                精米程度も、玄米・白米・五分搗き・七分搗きから選んでいただけます。<br />
                また、年間定期宅配の「きよら米会員」も毎年募集中です。
              </p>
            </div>
            <div className="content-block__img">
              <img src={"/images/goods-1.jpg"} alt="きよら米" />
            </div>
          </div>
        </div>
        <div className="content-block">
          <h3 className="page-subtitle">釜煎りハーブソルト</h3>
          <div className="content-block__content">
            <div className="content-block__text">
              <p>
                沖縄の天然塩に19種類のハーブ＆スパイスをじっくり煎り込みました。<br />
                どんな料理にもぴったりですので、オリジナルの使い方をみつけてみてください。
              </p>
            </div>
            <div className="content-block__img">
              <img src={"/images/goods-2.jpg"} alt="釜煎りハーブソルト" />
            </div>
          </div>
        </div>
        <div className="content-block">
          <h3 className="page-subtitle">～ やさしいソースシリーズ ～</h3>
          <div className="content-block__content">
            <div className="content-block__text">
              <div className="content-block__subblock">
                <h4 className="has-icon"><i className="fa fa-tint"></i>イタリアンハーブソース</h4>
                <p>
                  農園産イタリアントマト、玉ねぎ、にんにく、バジルなどで作ったトマトソースです。<br />
                  パスタやピザはもちろんのこと、煮込み料理にもばっちりです。<br />
                  ご自宅で本格イタリアンをぜひどうぞ！
                </p>
              </div>
              <div className="content-block__subblock">
                <h4 className="has-icon"><i className="fa fa-tint"></i>はちみつブルーベリーソース</h4>
                <p>
                  農園産ブルーベリー100%とハチミツのみで作った、甘さ控えめのソースです。<br />
                  パンケーキやヨーグルトに添えてお召し上がりください。
                </p>
              </div>
            </div>
            <div className="content-block__img">
              <img src={"/images/goods-3.jpg"} alt="やさしいソースシリーズ" />
            </div>
          </div>
        </div>
        <div className="content-block">
          <h3 className="page-subtitle">～ オリジナルハーブティー ～</h3>
          <div className="content-block__content">
            <div className="content-block__text">
              <div className="content-block__subblock">
                <h4 className="has-icon"><i className="fa fa-tint"></i>さわやかハーブティー</h4>
                <p>
                  レモングラス・レモンバームなどをブレンドした、レモンフレーバーのさわやかなお茶です。<br />
                  胃腸をすっきりさせる効果もあります。<br />
                  夏場はアイスでもどうぞ。
                </p>
              </div>
              <div className="content-block__subblock">
                <h4 className="has-icon"><i className="fa fa-tint"></i>おやすみハーブティー</h4>
                <p>
                  バラとラベンダーの香りが心地よいお茶です。<br />
                  お休み前やリラックスタイムにぜひお飲みください。
                </p>
              </div>
            </div>
            <div className="content-block__img">
              <img src={"/images/goods-4.jpg"} alt="オリジナルハーブティー" />
            </div>
          </div>
        </div>
      </div>
      <div className="actions">
        <Link to={`/purchase`} className="btn">商品のご購入はこちら</Link>
      </div>
    </>
  );
}