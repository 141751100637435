import { ReactComponent as ApplemintLogo } from "../images/logo.svg";
import { ReactComponent as KazenomoriLogo } from "../images/logo-k.svg";
import { Link } from "react-router-dom";
import {useState} from 'react';

export default function PageHeader() {
  const [isActive, setIsActive] = useState(false);
  const toggleMenu = event => {
    setIsActive(current => !current);
  };
  const closeMenu = event => {
    setIsActive(false); 
  };
  return (
    <>
      <header className="page-header">
        <div className="page-top-info">
          <div className="container">
            <ul>
              <li>
                <i className="fa fa-fw fa-map-marker"></i>
                <span><span>〒869-2402</span> <span>熊本県阿蘇郡南小国町満願寺312</span></span>
              </li>
              <li>
                <i className="fa fa-fw fa-phone"></i>
                <span>0967-42-1553</span>
              </li>
              <li className="contact">
                <i className="fa fa-fw fa-envelope"></i>
                <Link to={`/contact`}>お問い合わせ</Link>
              </li>
              <li className="shop">
                <i className="fa fa-fw fa-shopping-cart"></i>
                <a href="http://store.shopping.yahoo.co.jp/syokunosanpo/a4aacac6a1.html" target="_blank" rel="noreferrer">ネットショップ</a>
              </li>
              <li className="fb">
                <i className="fa fa-fw fa-facebook-square"></i>
                <a href="https://www.facebook.com/pages/%E3%81%82%E3%81%A3%E3%81%B7%E3%82%8B%E3%81%BF%E3%82%93%E3%81%A8%E3%83%8F%E3%83%BC%E3%83%96%E8%BE%B2%E5%9C%92%E9%A2%A8%E3%81%AE%E3%82%82%E3%82%8A/1424061631175383" target="_blank" rel="noreferrer">Facebook</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="top-nav">
          <div className="container">
            <h1 className="logo"><Link to={`/`}><ApplemintLogo height={88} /><span className="visually-hide">あっぷるみんと</span></Link></h1>
            <nav>
              <button className={isActive ? 'menu-toggle active' : 'menu-toggle'} onClick={toggleMenu}>MENU<i className="fa fa-fw fa-caret-down"></i></button>
              <ul>
                <li className="restaurant"><Link to={`/restaurant`} onClick={closeMenu}><span className="title">自然食レストラン</span><KazenomoriLogo height={30} /><span className="visually-hide">風のもり</span></Link></li>
                <li>
                  <Link to={`/farm`} onClick={closeMenu}>農園</Link>
                </li>
                <li>
                  <Link to={`/goods`} onClick={closeMenu}>商品紹介</Link>
                </li>
                <li>
                  <Link to={`/purchase`} onClick={closeMenu}>商品購入</Link>
                </li>
                <li>
                  <Link to={`/efforts`} onClick={closeMenu}>取り組み</Link>
                </li>
                <li>
                  <Link to={`/access`} onClick={closeMenu}>アクセス</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}