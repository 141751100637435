export default function Farm() {
  return (
    <>
      <h2 className="page-title">
        <i className="fa fa-fw fa-leaf"></i>
        農園
      </h2>
      <div className="sp-content-block">
        <h3>
          きよら農法について
        </h3>
        <p>
          あっぷるみんとハーブ農園では、長年取り組んでいる無農薬、無化学肥料栽培を<em>きよら農法</em>と名付けました。これはもともと一個人に一農法をスローガンに取り組んできた農園の集大成であり、またここ南小国で自然農法を確立することでもあります。まだまだ時間はかかりますがいろんな情報、経験を積み重ねて完成したいと思います。<br />
          この農法をより確かなものにするために季節ごと、新しい資材や微生物を取り入れるごと栽培履歴を公開しようと思います。
        </p>
      </div>

      <div className="content-block-wrapper content-block-wrapper--iswitch">
        <div className="content-block">
          <h3 className="page-subtitle">田んぼ</h3>
          <div className="content-block__content">
            <div className="content-block__text">
            <p>
              昭和61年より「自然栽培」（無農薬、無化学肥料、無除草剤栽培）を続けている「きよら米づくり」（1.4ha）を農園のベースと考え毎年大切に育てています。<br />
              また農業を通じて環境を良くしていく「環境保全型農業」を目指し、源流に位置する筑後川を少しでもキレイにしていきたいという思いも込め自然栽培にこだわっています。<br />
              日本人の主食である米を出来るだけ安全に少しでも美味しくと毎年作っています。<br />
              隣する「自然食レストラン　風のもり」でもお出ししていますので、召し上がって見てください。
            </p>
            </div>
            <div className="content-block__img">
              <img src={"/images/farm-1.jpg"} alt="田んぼ" />
            </div>
          </div>
        </div>
        <div className="content-block">
          <h3 className="page-subtitle">畑</h3>
          <div className="content-block__content">
            <div className="content-block__text">
              <h4 className="has-icon"><i className="fa fa-tint"></i>イタリアントマト</h4>
              <p>
                赤いの美しい品種「オスカー」を中心にハウスで自然栽培しています。通常の丸いトマトよりも水分量が少なく、味が濃いのが特徴です。<br />
                加工用と思われがちですが、生で食べてもとても美味しいです。
              </p>
            </div>
            <div className="content-block__img">
              <img src={"/images/farm-2-1.jpg"} alt="イタリアントマト" />
            </div>
          </div>
          <div className="content-block__content">
            <div className="content-block__text">
              <h4 className="has-icon"><i className="fa fa-tint"></i>ブルーベリー</h4>
              <p>
                ホームベル種を中心としたブルーベリー500本以上（40a）を自然栽培しています。<br />
                また、夏季（7月下旬〜9月中旬、詳しくは<a href="http://blog.goo.ne.jp/applemint-kazenomori" target="_blank" rel="noreferrer">ブログ</a>をご覧下さい）には「ブルーベリー摘み」を実施しています。<br />
                夏の日差しをたっぷり浴びて、甘くたわわに実ったブルーベリーをたっぷりお召し上がりください。
              </p>
            </div>
            <div className="content-block__img">
              <img src={"/images/farm-2-2.jpg"} alt="ブルーベリー" />
            </div>
          </div>
          <div className="content-block__content">
            <div className="content-block__text">
              <h4 className="has-icon"><i className="fa fa-tint"></i>ハーブ</h4>
              <p>
                あっぷるみんとの加工品に使用するパジル、パセリ、レモングラス、レモンバーム、ミント、ローズマリーなどを自然栽培しています。<br />
                できるだけ野生に近い形で育て、味や香りの強いものを作ろうと頑張っています。<br />
                もっとも自然栽培の腕が試されるところです。
              </p>
            </div>
            <div className="content-block__img">
              <img src={"/images/farm-2-3.jpg"} alt="ハーブ" />
            </div>
          </div>
          <div className="content-block__content">
            <div className="content-block__text">
              <h4 className="has-icon"><i className="fa fa-tint"></i>野菜</h4>
              <p>
                レストランで必要な野菜を季節に合わせて、雨よけハウスや路地で栽培しています。
              </p>
            </div>
            <div className="content-block__img">
              <img src={"/images/farm-2-4.jpg"} alt="野菜" />
            </div>
          </div>
        </div>
        <div className="content-block">
          <h3 className="page-subtitle">加工所</h3>
          <div className="content-block__content">
            <div className="content-block__text">
              <p>
                素材の味を生かして、旬の味そのままをお手元にお届けできるような商品つくりを心掛けています。<br />
                体にもやさしい物をと食品添加物は一切使用せず、植物性の材料にこだわっています。<br />
                農園がまるごとがつまった味をぜひお楽しみください。
              </p>
            </div>
            <div className="content-block__img">
              <img src={"/images/farm-3.jpg"} alt="加工所" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}