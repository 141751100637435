import { Link } from "react-router-dom";

export default function ContactSent() {
  return (
    <>
      <div className="mail-sent">
        お問い合わせを受け取りました。なるべく早く返信します。
      </div>

      <div className="actions">
        <Link to={`/`} className="btn">トップページに戻る</Link>
      </div>
    </>
  );
}