export const goodsData = [
  {
    type: "produce",
    name: "きよら米 白米",
    img: "rice-white",
    price: 750,
    spec: "900g（1パック）",
  },
  {
    type: "produce",
    name: "きよら米 玄米",
    img: "rice-brown",
    price: 750,
    spec: "1kg（1パック）",
  },
/*  {
    type: "produce",
    name: "きよら米 もち米",
    price: 750,
    spec: "900g",
  },
*/
  {
    type: "produce",
    name: "きよら米ブレンド雑穀米",
    img: "rice-mixed-300",
    price: 1250,
    spec: "300g",
  },
  {
    type: "processed",
    name: "釜煎りハーブソルト 瓶入り",
    img: "salt-bottle",
    price: 672,
    spec: "85g",
  },
  {
    type: "processed",
    name: "釜煎りハーブソルト 袋入り",
    img: "salt-pack",
    price: 594,
    spec: "100g",
  },
  {
    type: "processed",
    name: "イタリアンハーブソース",
    img: "sauce-italy",
    price: 864,
    spec: "205g",
  },
  {
    type: "processed",
    name: "はちみつブルーベリーソース",
    img: "sauce-bluberry-honey",
    price: 864,
    spec: "220g",
  },
  {
    type: "processed",
    name: "黒糖ブルーベリー",
    img: "sauce-bluberry-suger",
    price: 864,
    spec: "220g",
  },
  /*{
    type: "processed",
    name: "イタリアントマトピューレ",
    price: 1200,
    spec: "800g",
  },*/
  {
    type: "processed",
    name: "さわやかハーブティー",
    img: "tea-refreshing",
    price: 572,
    spec: "20g(約30杯)",
  },
  {
    type: "processed",
    name: "おやすみハーブティー",
    img: "tea-goodnight",
    price: 572,
    spec: "20g(約30杯)",
  },
  {
    type: "processed",
    name: "うるおいハーブティー",
    img: "tea-moist",
    price: 572,
    spec: "18g(約30杯)",
  },
  {
    type: "processed",
    name: "あったかハーブティー",
    img: "tea-warm",
    price: 572,
    spec: "20g(約30杯)",
  },
  {
    type: "processed",
    name: "黒ごまドレッシング",
    img: "dressing-sesame",
    price: 594,
    spec: "120g",
  },
  {
    type: "processed",
    name: "甘酒",
    img: "amazake-plain",
    price: 1296,
    spec: "720ml",
  },
  {
    type: "alcohol",
    name: "満願寺 25度",
    img: "alcohol-25",
    price: 2035,
    spec: "720ml",
  },
  {
    type: "alcohol",
    name: "満願寺 原酒43度",
    img: "alcohol-43",
    price: 3300,
    spec: "720ml",
  },
/*  {
    type: "limited",
    name: "どぶろく鍋セット(冬季限定)",
    price: 3300,
    spec: "1セット",
  },*/
]